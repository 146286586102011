
import { ComponentType } from '../Types';

export const componentImages: Record<ComponentType, string> = {
  hero: '/components/hero.png',
  'horizontal-hero': '/components/horizontal-hero.png',
  'grid-features': '/components/grid-features.png',
  'social-links': '/components/social-links.png',
  text: '/components/text.png',
  features: '/components/features.png',
  testimonial: '/components/testimonial.png',
  cta: '/components/cta.png',
  accordion: '/components/accordion.png',
  cards: '/components/cards.png',
  logo: '/components/logo.png',
  space: '/components/space.png',
};
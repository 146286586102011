import React, { ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SocialLinksSection } from '../../Types';


interface SocialLinksFormProps {
    initialContent: SocialLinksSection['content'];
    onSave: (updatedContent: SocialLinksSection['content']) => void;
}

const SocialLinksForm: React.FC<SocialLinksFormProps> = ({ initialContent, onSave }) => {
    const [content, setContent] = useState<SocialLinksSection['content']>(initialContent);

    // Handle changes for individual social links
    const handleSocialLinkChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        const updatedLinks = [...content.social_links];
        updatedLinks[index] = {
            ...updatedLinks[index],
            [name]: value
        };
        setContent(prevContent => ({ ...prevContent, social_links: updatedLinks }));
    };

    // Add a new social link
    const addSocialLink = () => {
        setContent(prevContent => ({
            ...prevContent,
            social_links: [
                ...prevContent.social_links,
                { type: '', link: '', icon_url: '' } // Default new social link
            ]
        }));
    };

    // Remove a social link
    const removeSocialLink = (index: number) => {
        setContent(prevContent => ({
            ...prevContent,
            social_links: prevContent.social_links.filter((_, i) => i !== index)
        }));
    };

    // Handle change for position
    const handlePositionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setContent(prevContent => ({ ...prevContent, position: e.target.value }));
    };

    // Handle changes for title, background color, and text color
    const handleBasicChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    };

    // Save the content
    const handleSave = () => {
        onSave(content);
    };

    return (
        <div>
            <label>Colors:</label>
            <div className="grid-div">
                <small>Background Color:</small>
                <input
                    type="color"
                    name="background_color"
                    value={content.background_color}
                    onChange={handleBasicChange}
                />
                <small>Text Color:</small>
                <input
                    type="color"
                    name="text_color"
                    value={content.text_color}
                    onChange={handleBasicChange}
                />
            </div>
            <label>Title:</label>
            <input
                type="text"
                name="title"
                value={content.title}
                onChange={handleBasicChange}
            />
            <div>
                <label>Position:</label>
                <select name="position" value={content.position} onChange={handlePositionChange}>
                    <option value="left">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                </select>
            </div>

            {/* Social Links */}
            {content.social_links.map((socialLink, index) => (
                <div key={index} className="section-item">
                    <label>Social Media:</label>
                    <select
                        name="type"
                        value={socialLink.type}
                        onChange={(e) => handleSocialLinkChange(index, e)}
                    >
                        <option value="">Select</option>
                        <option value="facebook">Facebook</option>
                        <option value="twitter">Twitter</option>
                        <option value="instagram">Instagram</option>
                        <option value="tiktok">TikTok</option>
                        <option value="linkedin">LinkedIn</option>
                        <option value="spotify">Spotify</option>
                        <option value="youtube">YouTube</option>

                    </select>

                    <label>Link:</label>
                    <input
                        type="text"
                        name="link"
                        value={socialLink.link}
                        onChange={(e) => handleSocialLinkChange(index, e)}
                    />

                    <button className="delete-button" onClick={() => removeSocialLink(index)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                    </button>
                </div>
            ))}

            <button onClick={addSocialLink}>
                <FontAwesomeIcon icon={faPlus} /> Add Link
            </button>

            <hr />
            <button onClick={handleSave}>Save</button>
        </div>
    );
};

export default SocialLinksForm;

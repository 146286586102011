import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";

// Define the types for message context
interface MessageContextType {
    showMessage: (status: "success" | "error", message: string) => void;
    popupInfo: PopupInfo;
}

interface PopupInfo {
    status: "success" | "error";
    message: string;
}

// Create the context with an initial empty value
const MessageContext = createContext<MessageContextType | undefined>(undefined);

// Create a provider component
export const MessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [popupInfo, setPopupInfo] = useState<PopupInfo>({ status: "success", message: "" });

    const showMessage = useCallback((status: "success" | "error", message: string) => {
        setPopupInfo({ status, message });
    }, []);

    return (
        <MessageContext.Provider value={{ showMessage, popupInfo }}>
            {children}
        </MessageContext.Provider>
    );
};

// Custom hook to use the message context
export const useMessage = (): MessageContextType => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error("useMessage must be used within a MessageProvider");
    }
    return context;
};

import React, { createContext, useContext, useState, ReactNode } from "react";
import ConfirmationDialog from "./ConfirmationDialog";

// Define the types for the context
interface ConfirmationDialogContextType {
    showConfirmation: (
        message: string,
        inputRequired?: boolean,
        inputLabel?: string,
        expectedInputValue?: string,
        title?: string // Optional title
    ) => Promise<boolean>; // Return a promise that resolves to true/false
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType | undefined>(undefined);

// Create a provider component
export const ConfirmationDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [dialogProps, setDialogProps] = useState<{
        message: string;
        inputRequired: boolean;
        inputLabel: string;
        expectedInputValue: string;
        title: string;
        resolve: (value: boolean) => void; // Resolve function for the promise
    }>({
        message: "",
        inputRequired: false,
        inputLabel: "",
        expectedInputValue: "",
        title: "Confirmation",
        resolve: () => { },
    });

    const showConfirmation = (
        message: string,
        inputRequired: boolean = false,
        inputLabel: string = "Type to confirm",
        expectedInputValue: string = "",
        title: string = "Confirmation"
    ): Promise<boolean> => {
        return new Promise((resolve) => {
            setDialogProps({
                message,
                inputRequired,
                inputLabel,
                expectedInputValue,
                title,
                resolve,
            });
            setIsVisible(true);
        });
    };

    const handleConfirm = () => {
        dialogProps.resolve(true); // Resolve the promise with true when confirmed
        setIsVisible(false);
    };

    const handleCancel = () => {
        dialogProps.resolve(false); // Resolve the promise with false when canceled
        setIsVisible(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ showConfirmation }}>
            {children}
            {isVisible && (
                <ConfirmationDialog
                    title={dialogProps.title}
                    message={dialogProps.message}
                    inputRequired={dialogProps.inputRequired}
                    inputLabel={dialogProps.inputLabel}
                    expectedInputValue={dialogProps.expectedInputValue}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </ConfirmationDialogContext.Provider>
    );
};

// Custom hook to use the confirmation dialog context
export const useConfirmationDialog = (): ConfirmationDialogContextType => {
    const context = useContext(ConfirmationDialogContext);
    if (!context) {
        throw new Error("useConfirmationDialog must be used within a ConfirmationDialogProvider");
    }
    return context;
};

// src/pages/OtpLogin.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import './OtpLogin.css';
import ApiService from '../../services/ApiService';
import { VerifyOtpResponseDTO } from '../../services/dtos'; // Import the DTO
import { useMessage } from '../../components/PopUp/MessageContex';

const OtpLogin: React.FC = () => {
    const [step, setStep] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [accountCreationAccepted, setAccountCreationAccepted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false); // New state for loading
    const navigate = useNavigate(); // Initialize navigate function
    const { showMessage } = useMessage();

    // Function to handle sending OTP
    const sendOtp = async () => {
        if (!accountCreationAccepted) {
            setMessage("You must accept the account creation if one doesn't exist yet.");
            return;
        }

        if (!termsAccepted) {
            setMessage('You must accept the privacy policy and terms and conditions to proceed.');
            return;
        }

        setIsLoading(true); // Set loading state to true
        const responseMessage = await ApiService.sendOtp(email);
        setIsLoading(false); // Set loading state to false
        showMessage(responseMessage.status, responseMessage.message);
        if (responseMessage.status === 'success') {
            setStep(2); // Move to the next step
        }
    };

    // Function to handle verifying OTP
    const verifyOtp = async () => {
        setIsLoading(true); // Set loading state to true
        const response: VerifyOtpResponseDTO = await ApiService.verifyOtp(email, parseInt(otp, 10));
        setIsLoading(false); // Set loading state to false

        if (response.status === 'success' && response.token) {
            localStorage.setItem('authToken', response.token); // Store the token
            navigate('/links'); // Navigate to the home page or dashboard
            window.location.reload();
        } else {
            showMessage(response.status, response.message);
            setStep(2); // Move to the next step
        }
    };

    return (
        <div className="otp-login">
            <h1>Login</h1>
            <p>We use One-Time Passwords (OTP) as an authentication method to enhance your privacy and security. By using OTP, we can authenticate users without storing sensitive personal information like passwords. This minimizes the amount of data we retain, reducing the risk of unauthorized access and protecting your privacy.</p>

            {step === 1 && (
                <div className="email-step">
                    <h2>Step 1: Enter Your Email</h2>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                    />
                    <div className="terms">

                        <label>
                            <input
                                type="checkbox"
                                checked={accountCreationAccepted}
                                onChange={(e) => setAccountCreationAccepted(e.target.checked)}
                            />
                            I agree to the creation of an account if one does not already exist, with the understanding that it will be deleted after 1 hour if not used.
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                            I accept the <a href="/#privacy-policy">Privacy Policy</a> and <a href="/#terms-and-conditions">Terms and Conditions</a>
                        </label>
                    </div>
                    <button onClick={sendOtp} disabled={isLoading}>
                        {isLoading ? 'Sending...' : 'Send OTP'}
                    </button>
                </div>
            )}

            {step === 2 && (
                <div className="otp-step">
                    <h2>Step 2: Enter the OTP Code</h2>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter the OTP code"
                    />
                    <button onClick={verifyOtp} disabled={isLoading}>
                        {isLoading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </div>
            )}

            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default OtpLogin;
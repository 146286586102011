const groupedTemplateOptions = [
    {
        label: "Social Media",
        options: [
            "Influencer Profile",
            "Social Media Marketing",
            "Community Hub",
            "Event Promotion"
        ]
    },
    {
        label: "Company Services",
        options: [
            "Tech Startup",
            "Financial Consulting",
            "Healthcare Services",
            "Real Estate Agency",
            "Legal Advisory",
            "E-commerce Solutions",
            "Digital Marketing Agency"
        ]
    },
    {
        label: "Sports",
        options: [
            "Fitness Coaching",
            "Sports Team Fan Page",
            "Athletic Events",
            "Sports Gear Store",
            "Personal Training Services"
        ]
    },
    {
        label: "Music",
        options: [
            "Musician/Band Portfolio",
            "Music Event Promotion",
            "Online Music Store",
            "Podcast Channel",
            "Music Streaming Service"
        ]
    },
    {
        label: "Personal Portfolio",
        options: [
            "Freelance Designer",
            "Photographer Portfolio",
            "Writer's Showcase",
            "Personal Blog"
        ]
    },
    {
        label: "Education",
        options: [
            "Online Courses",
            "Educational Institution",
            "Tutoring Services",
            "E-learning Platform"
        ]
    },
    {
        label: "Non-Profit",
        options: [
            "Charity Organization",
            "Community Project",
            "Environmental Campaign"
        ]
    },
    {
        label: "Entertainment",
        options: [
            "Movie Promotion",
            "Gaming Community",
            "Event Booking"
        ]
    },
    {
        label: "Travel & Tourism",
        options: [
            "Travel Blog",
            "Tour Packages",
            "Hotel/Resort Promotion"
        ]
    },
    {
        label: "Food & Beverage",
        options: [
            "Restaurant/Cafe",
            "Food Blog",
            "Online Food Delivery"
        ]
    }
];

export default groupedTemplateOptions;
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
import './QrCodePopup.css';

interface QrCodePopupProps {
    link: string;
    isOpen: boolean;
    onClose: () => void;
}

const QrCodePopup: React.FC<QrCodePopupProps> = ({ link, isOpen, onClose }) => {
    const svgRef = useRef<HTMLDivElement>(null);

    const handleDownload = () => {
        if (svgRef.current) {
            // Get the SVG element and create an off-screen canvas
            const svgElement = svgRef.current.querySelector('svg');
            if (!svgElement) {
                return;
            }
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const svgSize = svgElement.getBoundingClientRect();

            // Set canvas size to match the SVG size
            canvas.width = svgSize.width;
            canvas.height = svgSize.height;

            // Convert the SVG to an image
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const img = new Image();
            img.onload = () => {
                if (context) {
                    // Draw the SVG onto the canvas
                    context.drawImage(img, 0, 0);
                    // Convert the canvas to a data URL and trigger the download
                    const pngData = canvas.toDataURL('image/png');
                    const link = document.createElement('a');
                    link.href = pngData;
                    link.download = 'qr-code.png';
                    link.click();
                }
            };
            // Set the image source to the serialized SVG data
            img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <p>This QRCode will redirect users to:</p>
                <p>{link}</p>
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                {/* QR Code with ref */}
                <div ref={svgRef}>
                    <QRCode value={link} size={254} />
                </div>
                {/* Download Button */}
                <button className="download-button" onClick={handleDownload}>
                    Download QR Code
                </button>
            </div>
        </div>,
        document.body
    );
};

export default QrCodePopup;

// src/pages/UrlInputPage.tsx
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to handle route parameters
import UrlInput from '../../components/UrlInput/UrlInput'; // Import the UrlInput component
import './UrlInputPage.css'; // Import CSS for styling

const UrlInputPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Extract URL ID from route parameters
    const navigate = useNavigate(); // Initialize navigate function

    const onShorten = (url: string) => {
        navigate("/links");
    };

    return (
        <div className="url-input-page">
            <h2>{id ? 'Edit URL - ' + id : 'Add New URL'}</h2>
            <UrlInput
                onShorten={onShorten}
                expandable={true}
            />
        </div>
    );
};

export default UrlInputPage;
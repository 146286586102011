import React, { useEffect, useState } from 'react';
import ApiService from '../../services/ApiService';

interface UrlClicksProps {
    urlId: string
}
const UrlClicks: React.FC<UrlClicksProps> = ({ urlId }) => {
    const [count, setCount] = useState("...");

    useEffect(() => {
        const fetchClicks = async (urlId: string) => {
            const response = await ApiService.getTotalClicks(urlId);
            setCount(response.total_clicks + "");
        }

        fetchClicks(urlId);
    }, [])

    return <>{count}</>
}

export default UrlClicks;
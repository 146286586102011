import React, { useEffect, useState } from 'react';
import './PricingSection.css';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import ApiService from '../../services/ApiService';
import { SubscriptionDTO } from '../../services/dtos';
import { useMessage } from '../PopUp/MessageContex';
import { useConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialogProvider';

function PricingSectionAdmin() {
    const { showConfirmation } = useConfirmationDialog();
    const [loading, setLoading] = useState(false);
    const [currentPlan, setCurrentPlan] = useState<SubscriptionDTO>({
        status: 'error', // Assuming status can only be 'success' or 'error'
        settings: {
            stripeKey: '',
            plans: []
        },
        plan: '',
        subscription_id: '',
        monthly_links: 0,
        monthly_links_limit: 0,
        landing_pages: 0,
        landing_pages_limit: 0,
        custom_slug: 0,
        custom_slug_limit: 0,
        statistics_weeks: 0,
        statistics_weeks_limit: 0,
        ends_at: '',
        is_canceled: 0,
    });
    const [plans, setPlans] = useState<{ monthly: string, annual: string }[]>([]);
    const [stripeKey, setStripeKey] = useState<string>('');

    const { showMessage } = useMessage();

    // State to track if annual or monthly pricing is selected
    const [isAnnual, setIsAnnual] = useState(true);

    const isPlanCurrent = (index: number, isAnnual: boolean) => {
        if (plans.length < index || plans.length === 0) {
            return;
        }

        const plan = plans[index];
        const priceId = isAnnual ? plan.annual : plan.monthly;

        return priceId === currentPlan.plan;
    }

    const initCheckoutSession = async (index: number, isAnnual: boolean) => {
        if (plans.length < index) {
            return;
        }

        const plan = plans[index];
        const priceId = isAnnual ? plan.annual : plan.monthly;

        if (priceId == null) {
            return;
        }

        setLoading(true);
        const sessionId = await ApiService.initPaymentSession(priceId);
        if (sessionId != null) {
            const stripe = await loadStripe(stripeKey);
            if (stripe) {
                const { error } = await stripe.redirectToCheckout({
                    sessionId: sessionId + ""
                });

                if (error) {
                    console.error('Error during redirect: ', error);
                }
            }
        }
        setLoading(false);
    }

    const triggerCancelation = async (subscriptionId: string) => {
        const cancelationAnswer = await showConfirmation(
            'Your subscription cancellation will take effect after the current billing period ends. After this period, any links that exceed your package limitations will be disabled. To reactivate your existing subscription, please contact our support team.',
            true,
            "Type 'Cancel' to confirm",
            "Cancel",
            "Subscription Cancelation")

        if (!cancelationAnswer) {
            return;
        }
        const response = await ApiService.cancelSubscription(subscriptionId);

        showMessage(response.status, response.message);
    }

    const fetchPlan = async () => {
        const plan = await ApiService.getCurrentPlan();
        setPlans(plan.settings.plans);
        setStripeKey(plan.settings.stripeKey);
        setCurrentPlan(plan);

        if (plan.plan !== 'free') {
            for (let existingPlans of plans) {
                if (existingPlans.annual === plan.plan) {
                    setIsAnnual(true);
                } else if (existingPlans.monthly === plan.plan) {
                    setIsAnnual(false);
                }
            }
        }
    };

    useEffect(() => {
        fetchPlan();
    }, []);

    return (
        <section id="product" className="pricing-section">
            <h2>Choose the Plan that Fits Your Needs</h2>
            <p>From free access to premium control, our pricing plans offer flexibility and value.</p>
            <div className="pricing-toggle-buttons">
                <button
                    className={`toggle-button ${isAnnual ? 'active' : ''}`}
                    onClick={() => setIsAnnual(true)}
                >
                    Annual
                </button>
                <button
                    className={`toggle-button ${!isAnnual ? 'active' : ''}`}
                    onClick={() => setIsAnnual(false)}
                >
                    Monthly
                </button>
            </div>
            {plans.length === 0 ? '' :
                <div className="pricing-options">
                    <div className="pricing-option">
                        <h3>Free Plan</h3>
                        <p>€0</p>
                        <ul>
                            <li>Up to 5 links per month</li>
                            <li>QR Codes included</li>
                            <li>1 custom slug</li>
                            <li>1 custom landing page with tzz brand</li>
                            <li>2 weeks of statistics</li>
                        </ul>
                        {currentPlan.plan === "free" ? <p>CURRENT</p> : ''}
                    </div>
                    <div className="pricing-option">
                        <h3>Starter Plan</h3>
                        <p>{isAnnual ? '€50/year' : 'Only annual pricing available'}</p>
                        <ul>
                            <li>100 links per month</li>
                            <li>QR Codes included</li>
                            <li>10 custom slugs</li>
                            <li>10 custom landing pages with custom brand colors</li>
                            <li>1 month of statistics</li>
                        </ul>
                        {loading ? (
                            <span>Loading...</span>
                        ) : (
                            isAnnual ? (
                                isPlanCurrent(0, isAnnual) && currentPlan.is_canceled !== 1 ?
                                    <button className='cancelation-button' onClick={() => { triggerCancelation(currentPlan.subscription_id) }}>Cancel Subscription</button>
                                    : <button className='pay-button' disabled={currentPlan.plan !== 'free'} onClick={() => { initCheckoutSession(0, isAnnual) }}>Start now</button>) : ''
                        )}
                        <br></br>
                        {isPlanCurrent(0, isAnnual) && currentPlan.is_canceled !== 1 && currentPlan.ends_at ? <small>Next: {currentPlan.ends_at.split(" ")[0]} </small> : ''}
                        <br></br>
                        {isPlanCurrent(0, isAnnual) && currentPlan.is_canceled === 1 ? <small>Plan is cancled and will become inactive after expiration</small> : ''}
                    </div>
                    <div className="pricing-option">
                        <h3>Pro Plan</h3>
                        <p>{isAnnual ? '€120/year (Save 20%)' : '€15/month'}</p>
                        <ul>
                            <li>1,000 links per month</li>
                            <li>QR Codes included</li>
                            <li>50 custom slugs</li>
                            <li>50 custom landing pages with custom brand colors</li>
                            <li>2 months of statistics</li>
                        </ul>
                        {loading ? (
                            <span>Loading...</span>
                        ) : (
                            isPlanCurrent(1, isAnnual) && currentPlan.is_canceled !== 1 ?
                                <button className='cancelation-button' onClick={() => { triggerCancelation(currentPlan.subscription_id) }}>Cancel Subscription</button>
                                : <button className='pay-button' disabled={currentPlan.plan !== 'free'} onClick={() => { initCheckoutSession(1, isAnnual) }}>Start now</button>
                        )}
                        <br></br>
                        {isPlanCurrent(1, isAnnual) && currentPlan.is_canceled !== 1 && currentPlan.ends_at ? <small>Next: {currentPlan.ends_at.split(" ")[0]} </small> : ''}
                        <br></br>
                        {isPlanCurrent(1, isAnnual) && currentPlan.is_canceled === 1 ? <small>Plan is cancled and will become inactive after expiration</small> : ''}
                    </div>
                    <div className="pricing-option">
                        <h3>Business Plan</h3>
                        <p>{isAnnual ? '€280/year (Save 20%)' : '€35/month'}</p>
                        <ul>
                            <li>5,000 links per month</li>
                            <li>QR Codes included</li>
                            <li>500 custom slugs</li>
                            <li>500 custom landing pages with custom brand colors</li>
                            <li>3 months of statistics</li>
                        </ul>
                        {loading ? (
                            <span>Loading...</span>
                        ) : (isPlanCurrent(2, isAnnual) && currentPlan.is_canceled !== 1 ?
                            <button className='cancelation-button' onClick={() => { triggerCancelation(currentPlan.subscription_id) }}>Cancel Subscription</button>
                            : <button className='pay-button' disabled={currentPlan.plan !== 'free'} onClick={() => { initCheckoutSession(2, isAnnual) }}>Start now</button>
                        )}
                        <br></br>
                        {isPlanCurrent(2, isAnnual) && currentPlan.is_canceled !== 1 && currentPlan.ends_at ? <small>Next: {currentPlan.ends_at.split(" ")[0]} </small> : ''}
                        <br></br>
                        {isPlanCurrent(2, isAnnual) && currentPlan.is_canceled === 1 ? <small>Plan is cancled and will become inactive after expiration</small> : ''}
                    </div>
                </div>
            }
            <p className="user-guidance">
                If you have any issues or special requests, feel free to reach out to our support team at support@tzz.at.
            </p>
        </section >
    );
}

export default PricingSectionAdmin;
import { CardsSection, CtaSection, FeaturesSection, GridFeaturesSection, HeroSection, LogoSection, Section, SocialLinksSection, TestimonialSection } from "../Types";
import { landingPageDefaults } from "./landingPageDefaults";

const getPageContent = (templateName: string): Section[] => {
    switch (templateName.toLowerCase()) {
        case 'influencer profile':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: '#ffffff',
                        image_url: landingPageDefaults.profile,
                        position: 'center',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Welcome to My World',
                        subheading: 'Discover my journey, passions, and the stories that inspire me.',
                        background_image: '',
                        background_color: '#f7f3f2',
                        text_color: '#333333',
                        button_color: '#D14049',
                        cta_button: {
                            text: 'Follow Me',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#ffffff',
                        title: 'Connect with Me',
                        position: 'center',
                        text_color: '#333333',
                        social_links: [
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourprofile',
                            },
                            {
                                type: 'youtube',
                                link: 'https://youtube.com/yourchannel',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourhandle',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourpage',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'What My Followers Say',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'Following you has been an amazing journey. Your content is inspiring!',
                                name: 'Emily R.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'Love the authenticity and creativity in every post. Keep it up!',
                                name: 'Michael S.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#f7f3f2',
                        text_color: '#333333',
                        button_color: '#D14049',
                        text: 'Want to stay updated? Join my community and never miss a moment!',
                        button_text: 'Subscribe Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'social media marketing':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Boost Your Brand with Social Media Marketing',
                        subheading: 'Grow your audience and increase engagement with our proven strategies.',
                        background_image: '',
                        background_color: '#eaf7fd',
                        text_color: '#333333',
                        button_color: '#009688',
                        cta_button: {
                            text: 'Get Started',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Marketing Services',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Content Creation',
                                description: 'High-quality content tailored to your brand and audience.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Social Media Management',
                                description: 'Professional management of your social media channels.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#eaf7fd',
                        text_color: '#333333',
                        button_color: '#009688',
                        text: 'Ready to take your social media presence to the next level?',
                        button_text: 'Contact Us',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'community hub':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Welcome to Our Community Hub',
                        subheading: 'Connect, share, and grow with like-minded individuals.',
                        background_image: '',
                        background_color: '#f0f5ff',
                        text_color: '#333333',
                        button_color: '#3f51b5',
                        cta_button: {
                            text: 'Join Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#ffffff',
                        title: 'Follow Us on Social Media',
                        position: 'center',
                        text_color: '#333333',
                        social_links: [
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourcommunity',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourcommunity',
                            },
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourcommunity',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Community Features',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Forums',
                                description: 'Engage in discussions and share knowledge.',
                                link_name: 'Explore Forums',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Events',
                                description: 'Participate in community events and meetups.',
                                link_name: 'View Events',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
            ];

        case 'event promotion':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Promote Your Next Big Event',
                        subheading: 'Reach a wider audience and make your event a success.',
                        background_image: '',
                        background_color: '#fdf6e4',
                        text_color: '#333333',
                        button_color: '#ff9800',
                        cta_button: {
                            text: 'Learn More',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Why Promote with Us',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Wider Reach',
                                description: 'Promote your event to a larger audience.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Custom Campaigns',
                                description: 'Create tailored campaigns to fit your event needs.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#fdf6e4',
                        text_color: '#333333',
                        button_color: '#ff9800',
                        text: 'Ready to get started?',
                        button_text: 'Start Promoting',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'tech startup':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: '#ffffff',
                        image_url: landingPageDefaults.logo,
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Innovating the Future',
                        subheading: 'Empowering businesses with cutting-edge technology solutions.',
                        background_image: '',
                        background_color: '#f0f4f8',
                        text_color: '#333333',
                        button_color: '#007BFF',
                        cta_button: {
                            text: 'Learn More',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Core Services',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'AI-Powered Analytics',
                                description: 'Leverage data analytics to drive business decisions and growth.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Cloud Integration',
                                description: 'Seamless integration with cloud platforms for enhanced scalability.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Custom Software Development',
                                description: 'Tailored software solutions to meet your unique business needs.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'What Our Clients Say',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'The team delivered an exceptional solution that streamlined our operations.',
                                name: 'Jessica W.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'Their technology expertise helped us scale faster than we imagined.',
                                name: 'Mark T.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#007BFF',
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        text: 'Ready to innovate your business? Let’s build the future together!',
                        button_text: 'Get Started',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#ffffff',
                        title: 'Connect with Us',
                        position: 'right',
                        text_color: '#333333',
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourstartup',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourstartup',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourstartup',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];

        case 'company services':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: '#ffffff',
                        image_url: landingPageDefaults.logo, // Replace with actual logo URL
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Leading Services for Your Business',
                        subheading: 'Professional solutions tailored to your industry needs.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f2f2f2',
                        text_color: '#333333',
                        button_color: '#28a745',
                        cta_button: {
                            text: 'Contact Us',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Services',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Consulting',
                                description: 'Expert advice to guide your business strategies and decisions.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Market Analysis',
                                description: 'Comprehensive market research and insights tailored to your sector.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Custom Solutions',
                                description: 'Tailored services to fit the unique needs of your business.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Testimonials',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'Their consulting services have been invaluable to our growth.',
                                name: 'Anna S.',
                                image_url: landingPageDefaults.profile, // Replace with actual URL
                            },
                            {
                                quote: 'Their market analysis helped us understand our customers better.',
                                name: 'James L.',
                                image_url: landingPageDefaults.profile, // Replace with actual URL
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#28a745',
                        text_color: '#ffffff',
                        button_color: '#1e7e34',
                        text: 'Ready to transform your business? Get in touch with us today!',
                        button_text: 'Request a Consultation',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#ffffff',
                        title: 'Follow Us',
                        position: 'right',
                        text_color: '#333333',
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourcompany', // Replace with actual LinkedIn URL
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourcompany', // Replace with actual Twitter URL
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourcompany', // Replace with actual Facebook URL
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'fitness coaching':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Transform Your Body & Mind',
                        subheading: 'Personalized coaching to help you achieve your fitness goals.',
                        background_image: '',
                        background_color: '#f3f4f6',
                        text_color: '#333333',
                        button_color: '#ef5350',
                        cta_button: {
                            text: 'Start Training',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Coaching Services',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Personalized Plans',
                                description: 'Workouts tailored to your fitness level and goals.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Nutrition Guidance',
                                description: 'Custom meal plans to fuel your performance.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Success Stories',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'The coaching program helped me get in the best shape of my life!',
                                name: 'Sam K.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'Personalized plans made a huge difference in my progress.',
                                name: 'Mia S.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];

        case 'sports team fan page':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Welcome to the Fan Zone',
                        subheading: 'Join the excitement and cheer for your favorite team!',
                        background_image: '',
                        background_color: '#e0e0e0',
                        text_color: '#333333',
                        button_color: '#d32f2f',
                        cta_button: {
                            text: 'Join the Club',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#ffffff',
                        title: 'Follow Us',
                        position: 'center',
                        text_color: '#333333',
                        social_links: [
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourteam', // Replace with actual Facebook URL
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourteam', // Replace with actual Twitter URL
                            },
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourteam', // Replace with actual Instagram URL
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#d32f2f',
                        text_color: '#ffffff',
                        button_color: '#b71c1c',
                        text: 'Become a part of our fan community today!',
                        button_text: 'Sign Up',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'athletic events':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Upcoming Athletic Events',
                        subheading: 'Don’t miss out on our latest competitions and sports events.',
                        background_image: '',
                        background_color: '#ffebee',
                        text_color: '#333333',
                        button_color: '#c2185b',
                        cta_button: {
                            text: 'View Events',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Featured Events',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'City Marathon 2024',
                                description: 'Join us for the annual city marathon and push your limits.',
                                link_name: 'Register Now',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Triathlon Championship',
                                description: 'Compete in the ultimate test of endurance and skill.',
                                link_name: 'Learn More',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#c2185b',
                        text_color: '#ffffff',
                        button_color: '#880e4f',
                        text: 'Ready to take on the challenge? Sign up for an event today!',
                        button_text: 'Sign Up Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'sports gear store':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Your One-Stop Sports Gear Shop',
                        subheading: 'High-quality equipment and apparel for all your sporting needs.',
                        background_image: '',
                        background_color: '#e8eaf6',
                        text_color: '#333333',
                        button_color: '#3949ab',
                        cta_button: {
                            text: 'Shop Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Top Categories',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Running Shoes',
                                description: 'Find the perfect pair of shoes for your running needs.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Fitness Equipment',
                                description: 'Gear up with the latest fitness equipment.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Sports Apparel',
                                description: 'High-performance clothing for all sports.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#3949ab',
                        text_color: '#ffffff',
                        button_color: '#283593',
                        text: 'Gear up and stay ahead of the game!',
                        button_text: 'Browse Collection',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'personal training services':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Personal Training Services',
                        subheading: 'Customized training plans designed to fit your lifestyle.',
                        background_image: '',
                        background_color: '#f1f8e9',
                        text_color: '#333333',
                        button_color: '#388e3c',
                        cta_button: {
                            text: 'Book a Session',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Why Choose Us?',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Certified Trainers',
                                description: 'Work with experienced trainers certified in various disciplines.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Flexible Scheduling',
                                description: 'Book sessions that fit your schedule and lifestyle.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'What Our Clients Say',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'Personal training sessions have been a game-changer for my fitness.',
                                name: 'Alex M.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'The trainers are knowledgeable and supportive every step of the way.',
                                name: 'Taylor R.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];
        case 'musician/band portfolio':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Discover Our Music',
                        subheading: 'Explore our journey and the music that defines us.',
                        background_image: '', // Replace with actual URL
                        background_color: '#1a1a1a',
                        text_color: '#ffffff',
                        button_color: '#ff4081',
                        cta_button: {
                            text: 'Listen Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Music',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Latest Album',
                                description: 'Check out our latest release and dive into the new tracks.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Music Videos',
                                description: 'Watch the latest music videos and behind-the-scenes footage.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: '#1a1a1a',
                        title: 'Follow Us',
                        position: 'center',
                        text_color: '#ffffff',
                        social_links: [
                            {
                                type: 'spotify',
                                link: 'https://spotify.com/yourband', // Replace with actual Spotify URL
                            },
                            {
                                type: 'youtube',
                                link: 'https://youtube.com/yourchannel', // Replace with actual YouTube URL
                            },
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourband', // Replace with actual Instagram URL
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];

        case 'music event promotion':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Upcoming Events & Concerts',
                        subheading: 'Join us at our next live performance and experience the music firsthand.',
                        background_image: '', // Replace with actual URL
                        background_color: '#000000',
                        text_color: '#ffffff',
                        button_color: '#e91e63',
                        cta_button: {
                            text: 'Buy Tickets',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Upcoming Events',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Live at the Arena',
                                description: 'Join us for an unforgettable night of live music and energy.',
                                link_name: 'Get Tickets',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Summer Music Festival',
                                description: 'Experience the best of music at the summer festival.',
                                link_name: 'Learn More',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#e91e63',
                        text_color: '#ffffff',
                        button_color: '#880e4f',
                        text: 'Be a part of our next show!',
                        button_text: 'See Event Details',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'online music store':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Shop Music & Merch',
                        subheading: 'Get your hands on our latest albums, exclusive merchandise, and more.',
                        background_image: '', // Replace with actual URL
                        background_color: '#263238',
                        text_color: '#ffffff',
                        button_color: '#ff7043',
                        cta_button: {
                            text: 'Shop Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Featured Products',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Vinyl Records',
                                description: 'Explore our exclusive collection of vinyl records.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Band Merchandise',
                                description: 'Find t-shirts, hoodies, and other exclusive band merch.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#ff7043',
                        text_color: '#ffffff',
                        button_color: '#bf360c',
                        text: 'Don’t miss out on our latest collections!',
                        button_text: 'Shop All Products',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'podcast channel':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Tune Into Our Podcast',
                        subheading: 'Explore stories, interviews, and more on our weekly podcast.',
                        background_image: '', // Replace with actual URL
                        background_color: '#212121',
                        text_color: '#ffffff',
                        button_color: '#ff5722',
                        cta_button: {
                            text: 'Listen Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Podcast Episodes',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Latest Episode',
                                description: 'Check out the latest episode featuring special guest artists.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Popular Topics',
                                description: 'Dive into our most popular episodes and discussions.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#ff5722',
                        text_color: '#ffffff',
                        button_color: '#bf360c',
                        text: 'Never miss an episode!',
                        button_text: 'Subscribe',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'music streaming service':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Stream Music Anywhere, Anytime',
                        subheading: 'Access a world of music at your fingertips.',
                        background_image: '', // Replace with actual URL
                        background_color: '#0d47a1',
                        text_color: '#ffffff',
                        button_color: '#1e88e5',
                        cta_button: {
                            text: 'Start Free Trial',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Why Stream with Us?',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Unlimited Access',
                                description: 'Stream millions of songs with no interruptions.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'High-Quality Audio',
                                description: 'Enjoy music in the highest audio quality available.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#1e88e5',
                        text_color: '#ffffff',
                        button_color: '#0d47a1',
                        text: 'Get started with a free trial today!',
                        button_text: 'Try Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];
        case 'financial consulting':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Empowering Your Financial Future',
                        subheading: 'Expert financial consulting services to help you achieve your goals.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'Learn More',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Services',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'Investment Planning',
                                description: 'Tailored investment strategies to meet your financial goals.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Retirement Planning',
                                description: 'Secure your future with our comprehensive retirement planning.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Risk Management',
                                description: 'Protect your assets with our expert risk management solutions.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Testimonials',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'Their financial strategies have significantly boosted my investment portfolio.',
                                name: 'John D.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Professional and knowledgeable. They helped me plan my retirement with ease.',
                                name: 'Laura M.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Ready to take control of your financial future? Contact us today!',
                        button_text: 'Get Started',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Connect with Us',
                        position: 'right',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourconsulting',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourconsulting',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourconsulting',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'healthcare services':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'center',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Your Health, Our Priority',
                        subheading: 'Comprehensive healthcare services for you and your family.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'Book an Appointment',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Services',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'General Practice',
                                description: 'Comprehensive primary care for all ages.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Specialist Care',
                                description: 'Access to a network of experienced specialists.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Emergency Services',
                                description: '24/7 emergency care when you need it most.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Patient Testimonials',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'The healthcare services provided here are top-notch and very reliable.',
                                name: 'Emily W.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Professional staff and excellent care. Highly recommended!',
                                name: 'Michael H.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Need medical assistance? Schedule an appointment today!',
                        button_text: 'Contact Us',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Stay Connected',
                        position: 'center',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourhealthcareservices',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourhealthcare',
                            },
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourhealthcare',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'real estate agency':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Your Dream Home Awaits',
                        subheading: 'Find the perfect property with our expert real estate services.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'View Listings',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Services',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'Property Sales',
                                description: 'Helping you buy and sell properties seamlessly.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Property Management',
                                description: 'Professional management for your real estate assets.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Market Analysis',
                                description: 'Accurate market insights for informed decisions.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Testimonials',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'They made buying our first home a wonderful experience.',
                                name: 'Jessica T.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Professional and knowledgeable agents who really care.',
                                name: 'David L.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Ready to find your dream home? Contact us today!',
                        button_text: 'Get in Touch',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Follow Us',
                        position: 'right',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourrealestateagency',
                            },
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourrealestateagency',
                            },
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourrealestateagency',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'legal advisory':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Your Trusted Legal Advisors',
                        subheading: 'Expert legal advice to help you navigate complex legal matters.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'Consult with Us',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Legal Services',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'Corporate Law',
                                description: 'Comprehensive legal services for businesses and corporations.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Family Law',
                                description: 'Providing legal support for family-related matters.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Property Law',
                                description: 'Expert advice on property transactions and disputes.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Testimonials',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'Their expertise helped us navigate through complex legal issues.',
                                name: 'Robert K.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Professional and dedicated legal services.',
                                name: 'Sandra P.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Need legal advice? Schedule a consultation today!',
                        button_text: 'Contact Us',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Connect with Us',
                        position: 'right',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourlegaladvisory',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourlegaladvisory',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourlegaladvisory',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'e-commerce solutions':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'left',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Your One-Stop E-commerce Solution',
                        subheading: 'Grow your online business with our cutting-edge e-commerce solutions.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'Explore Our Services',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'What We Offer',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'Custom Storefronts',
                                description: 'Beautifully designed online stores tailored to your brand.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Payment Integration',
                                description: 'Seamless payment gateway integrations for secure transactions.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Analytics & Reporting',
                                description: 'Gain insights with real-time analytics and detailed reports.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Success Stories',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'Our online sales have doubled since we started using their e-commerce solutions.',
                                name: 'Anna J.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Excellent service and support. Highly recommended for any e-commerce business.',
                                name: 'George N.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Ready to transform your online business? Get in touch today!',
                        button_text: 'Get Started',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Stay Updated',
                        position: 'right',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourecommercesolutions',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourecommercesolutions',
                            },
                            {
                                type: 'twitter',
                                link: 'https://twitter.com/yourecommerce',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];
        case 'digital marketing agency':
            return [
                {
                    type: 'logo',
                    content: {
                        background_color: landingPageDefaults.background,
                        image_url: landingPageDefaults.logo,
                        position: 'center',
                    } as LogoSection['content'],
                },
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Boost Your Online Presence',
                        subheading: 'Expert digital marketing services to drive traffic and increase conversions.',
                        background_image: '',
                        background_color: landingPageDefaults.secondBackground,
                        text_color: landingPageDefaults.text,
                        button_color: landingPageDefaults.button,
                        button_text_color: '#ffffff',
                        cta_button: {
                            text: 'Get a Free Consultation',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Marketing Services',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        features: [
                            {
                                title: 'SEO Optimization',
                                description: 'Improve your search engine rankings and drive organic traffic.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'Social Media Marketing',
                                description: 'Engage with your audience and grow your brand on social media.',
                                icon_url: landingPageDefaults.icon,
                            },
                            {
                                title: 'PPC Advertising',
                                description: 'Maximize ROI with targeted pay-per-click advertising campaigns.',
                                icon_url: landingPageDefaults.icon,
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Client Success Stories',
                        background_color: landingPageDefaults.background,
                        text_color: landingPageDefaults.text,
                        items_color: landingPageDefaults.secondBackground,
                        testimonials: [
                            {
                                quote: 'Our traffic and sales have significantly increased since partnering with them.',
                                name: 'Linda C.',
                                image_url: landingPageDefaults.image,
                            },
                            {
                                quote: 'Their team is highly skilled and always delivers results.',
                                name: 'Tom R.',
                                image_url: landingPageDefaults.image,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: landingPageDefaults.button,
                        text_color: '#ffffff',
                        button_color: '#0056b3',
                        button_text_color: '#ffffff',
                        text: 'Ready to grow your business online? Contact us today!',
                        button_text: 'Contact Us',
                        button_url: '#',
                    } as CtaSection['content'],
                },
                {
                    type: 'social-links',
                    content: {
                        background_color: landingPageDefaults.background,
                        title: 'Connect with Us',
                        position: 'center',
                        text_color: landingPageDefaults.text,
                        social_links: [
                            {
                                type: 'linkedin',
                                link: 'https://linkedin.com/company/yourmarketingagency',
                            },
                            {
                                type: 'facebook',
                                link: 'https://facebook.com/yourmarketingagency',
                            },
                            {
                                type: 'instagram',
                                link: 'https://instagram.com/yourmarketingagency',
                            },
                        ],
                    } as SocialLinksSection['content'],
                },
            ];

        case 'freelance designer':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Creative Solutions for Your Brand',
                        subheading: 'Freelance designer specializing in branding, web design, and graphic art.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f4f4f4',
                        text_color: '#333333',
                        button_color: '#ff6f61',
                        cta_button: {
                            text: 'View My Work',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Services I Offer',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Brand Identity',
                                description: 'Crafting unique and memorable brand identities.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Web Design',
                                description: 'Creating responsive and visually appealing websites.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'What My Clients Say',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'An absolute pleasure to work with. Highly recommended for any design project!',
                                name: 'Alex T.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'Delivered high-quality design work that exceeded our expectations.',
                                name: 'Maria L.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];

        case 'photographer portfolio':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Capturing Moments That Last',
                        subheading: 'A professional photographer specializing in portraits, events, and landscapes.',
                        background_image: '', // Replace with actual URL
                        background_color: '#212121',
                        text_color: '#ffffff',
                        button_color: '#ffab40',
                        cta_button: {
                            text: 'Explore My Gallery',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Portfolio Highlights',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Portraits',
                                description: 'Capturing the essence of individuals through lens.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Landscapes',
                                description: 'Showcasing the beauty of nature in every shot.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#ffab40',
                        text_color: '#ffffff',
                        button_color: '#ef6c00',
                        text: 'Ready to capture your special moments? Get in touch!',
                        button_text: 'Contact Me',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'writer\'s showcase':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Words that Inspire',
                        subheading: 'A writer and storyteller crafting engaging content and compelling narratives.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f3e5f5',
                        text_color: '#333333',
                        button_color: '#7b1fa2',
                        cta_button: {
                            text: 'Read My Work',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Areas of Expertise',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Creative Writing',
                                description: 'Crafting compelling stories and narratives.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Copywriting',
                                description: 'Creating persuasive copy for brands and businesses.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Reader Testimonials',
                        background_color: '#f9f9f9',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'A true wordsmith. Their stories are engaging and thought-provoking.',
                                name: 'Emma W.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'Incredible talent. Their copy has significantly improved our brand messaging.',
                                name: 'Liam S.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];

        case 'personal blog':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Welcome to My Blog',
                        subheading: 'Sharing thoughts, experiences, and stories on life, travel, and more.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e1f5fe',
                        text_color: '#333333',
                        button_color: '#039be5',
                        cta_button: {
                            text: 'Explore My Blog',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Recent Posts',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'A Journey Through the Alps',
                                description: 'Exploring the beauty and serenity of the mountains.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Minimalist Living',
                                description: 'How simplifying life can lead to greater happiness.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#039be5',
                        text_color: '#ffffff',
                        button_color: '#0277bd',
                        text: 'Subscribe to my blog for the latest updates!',
                        button_text: 'Subscribe Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];
        case 'online courses':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Learn at Your Own Pace',
                        subheading: 'Discover a variety of courses designed to enhance your skills.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e8f5e9',
                        text_color: '#333333',
                        button_color: '#388e3c',
                        cta_button: {
                            text: 'Browse Courses',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Popular Courses',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Web Development',
                                description: 'Master front-end and back-end development with our comprehensive courses.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Digital Marketing',
                                description: 'Learn the strategies to excel in the digital marketing landscape.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#388e3c',
                        text_color: '#ffffff',
                        button_color: '#2e7d32',
                        text: 'Start your learning journey today!',
                        button_text: 'Get Started',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'educational institution':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Excellence in Education',
                        subheading: 'Providing quality education to nurture future leaders.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f0f4c3',
                        text_color: '#333333',
                        button_color: '#9e9d24',
                        cta_button: {
                            text: 'Learn More',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Our Programs',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Undergraduate Studies',
                                description: 'Offering a variety of undergraduate programs across multiple disciplines.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Postgraduate Studies',
                                description: 'Advanced studies and research opportunities for graduates.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'What Our Students Say',
                        background_color: '#f9fbe7',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'The institution has provided me with the best education and experiences.',
                                name: 'Jane D.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'An incredible learning environment with excellent faculty and facilities.',
                                name: 'John S.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];

        case 'tutoring services':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Personalized Tutoring Services',
                        subheading: 'Helping students achieve academic success through personalized tutoring.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e3f2fd',
                        text_color: '#333333',
                        button_color: '#1976d2',
                        cta_button: {
                            text: 'Find a Tutor',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Tutoring Services',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'One-on-One Tutoring',
                                description: 'Individualized attention to address specific learning needs.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Group Sessions',
                                description: 'Collaborative learning in a group environment.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#1976d2',
                        text_color: '#ffffff',
                        button_color: '#0d47a1',
                        text: 'Boost your academic performance with our expert tutors!',
                        button_text: 'Book a Session',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'e-learning platform':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Learn Anytime, Anywhere',
                        subheading: 'An innovative e-learning platform offering a wide range of courses.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f1f8e9',
                        text_color: '#333333',
                        button_color: '#388e3c',
                        cta_button: {
                            text: 'Explore Courses',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'grid-features',
                    content: {
                        title: 'Platform Features',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Interactive Learning',
                                description: 'Engage with interactive lessons and quizzes.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Certified Courses',
                                description: 'Earn certificates upon course completion.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as GridFeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#388e3c',
                        text_color: '#ffffff',
                        button_color: '#2e7d32',
                        text: 'Start your learning journey with us today!',
                        button_text: 'Sign Up Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];
        case 'charity organization':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Making a Difference Together',
                        subheading: 'Join us in our mission to support those in need.',
                        background_image: '', // Replace with actual URL
                        background_color: '#ffe0b2',
                        text_color: '#333333',
                        button_color: '#f57c00',
                        cta_button: {
                            text: 'Donate Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Causes',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Education for All',
                                description: 'Providing educational resources to underprivileged communities.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Healthcare Support',
                                description: 'Ensuring access to essential healthcare services.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Our Impact',
                        background_color: '#fff3e0',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'This organization has made a huge impact on our community.',
                                name: 'Sarah W.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'I am grateful for the support provided to my family.',
                                name: 'Ahmed K.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#f57c00',
                        text_color: '#ffffff',
                        button_color: '#e65100',
                        text: 'Join us in making a difference!',
                        button_text: 'Get Involved',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'community project':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Building Stronger Communities',
                        subheading: 'Collaborating with local partners to bring positive change.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e1f5fe',
                        text_color: '#333333',
                        button_color: '#0288d1',
                        cta_button: {
                            text: 'Join the Movement',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Initiatives',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Youth Programs',
                                description: 'Empowering the next generation with skills and opportunities.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Community Gardens',
                                description: 'Creating sustainable green spaces for all.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Stories from the Community',
                        background_color: '#e3f2fd',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'The youth programs have been life-changing for our community.',
                                name: 'Emily R.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'The community garden project has brought us all closer together.',
                                name: 'Carlos M.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#0288d1',
                        text_color: '#ffffff',
                        button_color: '#01579b',
                        text: 'Be a part of something bigger. Join our community projects!',
                        button_text: 'Learn More',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'environmental campaign':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Protecting Our Planet',
                        subheading: 'Join our efforts to create a sustainable future.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e8f5e9',
                        text_color: '#333333',
                        button_color: '#43a047',
                        cta_button: {
                            text: 'Get Involved',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Campaigns',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Tree Planting',
                                description: 'Restoring forests to combat climate change.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Ocean Cleanup',
                                description: 'Removing plastic waste to protect marine life.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#43a047',
                        text_color: '#ffffff',
                        button_color: '#2e7d32',
                        text: 'Together, we can make a difference for our planet.',
                        button_text: 'Take Action',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];
        case 'movie promotion':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Experience the Magic of Cinema',
                        subheading: 'Join us for an exclusive screening of the latest blockbuster.',
                        background_image: '', // Replace with actual URL
                        background_color: '#000000',
                        text_color: '#ffffff',
                        button_color: '#d32f2f',
                        cta_button: {
                            text: 'Watch Trailer',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Movie Highlights',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Star-Studded Cast',
                                description: 'Featuring an ensemble cast of award-winning actors.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Incredible Visuals',
                                description: 'Experience stunning visuals and groundbreaking effects.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#d32f2f',
                        text_color: '#ffffff',
                        button_color: '#b71c1c',
                        text: 'Don’t miss out on this cinematic experience!',
                        button_text: 'Get Tickets',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'gaming community':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Join the Ultimate Gaming Community',
                        subheading: 'Connect with fellow gamers, share strategies, and compete in tournaments.',
                        background_image: '', // Replace with actual URL
                        background_color: '#1b1b1b',
                        text_color: '#ffffff',
                        button_color: '#8e24aa',
                        cta_button: {
                            text: 'Join Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Community Features',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Live Streams',
                                description: 'Watch live streams of your favorite games and gamers.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Forums & Chat',
                                description: 'Engage in discussions and share gaming tips with the community.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#8e24aa',
                        text_color: '#ffffff',
                        button_color: '#6a1b9a',
                        text: 'Level up your gaming experience with us!',
                        button_text: 'Join the Community',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'event booking':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Book Your Next Event with Us',
                        subheading: 'From concerts to conferences, we make event booking easy.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e0f7fa',
                        text_color: '#333333',
                        button_color: '#00796b',
                        cta_button: {
                            text: 'Browse Events',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Upcoming Events',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Summer Music Festival',
                                description: 'Experience live performances from top artists.',
                                link_name: 'Book Now',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Tech Conference 2024',
                                description: 'Join industry leaders for a day of insights and networking.',
                                link_name: 'Get Tickets',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#00796b',
                        text_color: '#ffffff',
                        button_color: '#004d40',
                        text: 'Secure your spot at the hottest events!',
                        button_text: 'Book Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];
        case 'travel blog':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Wanderlust Diaries',
                        subheading: 'Sharing my adventures, travel tips, and hidden gems around the world.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f3e5f5',
                        text_color: '#333333',
                        button_color: '#8e24aa',
                        cta_button: {
                            text: 'Explore My Journey',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Recent Adventures',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Hiking the Alps',
                                description: 'An unforgettable journey through the Swiss Alps.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'Beach Hopping in Bali',
                                description: 'Exploring the beautiful beaches of Bali.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#8e24aa',
                        text_color: '#ffffff',
                        button_color: '#6a1b9a',
                        text: 'Follow my blog for more travel stories and tips!',
                        button_text: 'Subscribe Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'tour packages':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Explore Our Tour Packages',
                        subheading: 'Discover tailor-made tours designed for every kind of traveler.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e0f7fa',
                        text_color: '#333333',
                        button_color: '#00796b',
                        cta_button: {
                            text: 'View Packages',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Featured Tours',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Cultural Tours',
                                description: 'Immerse yourself in the culture and history of unique destinations.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Adventure Tours',
                                description: 'For thrill-seekers looking for the next big adventure.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#00796b',
                        text_color: '#ffffff',
                        button_color: '#004d40',
                        text: 'Book your dream tour today!',
                        button_text: 'Book Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'hotel/resort promotion':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Luxurious Stays Await You',
                        subheading: 'Experience world-class hospitality at our premium hotels and resorts.',
                        background_image: '', // Replace with actual URL
                        background_color: '#fff3e0',
                        text_color: '#333333',
                        button_color: '#f57c00',
                        cta_button: {
                            text: 'Book Your Stay',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Amenities',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Luxury Suites',
                                description: 'Stay in our beautifully designed suites with breathtaking views.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'World-Class Dining',
                                description: 'Indulge in gourmet cuisine at our on-site restaurants.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Guest Reviews',
                        background_color: '#fff8e1',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'An unforgettable stay with exceptional service and amenities.',
                                name: 'Laura P.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'The best resort experience I’ve ever had. Highly recommend!',
                                name: 'Michael T.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
            ];

        case 'restaurant/cafe':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Welcome to [Your Restaurant/Cafe Name]',
                        subheading: 'Experience exquisite flavors and a cozy atmosphere.',
                        background_image: '', // Replace with actual URL
                        background_color: '#ffe0b2',
                        text_color: '#333333',
                        button_color: '#ff7043',
                        cta_button: {
                            text: 'Reserve a Table',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Our Specialties',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Gourmet Dishes',
                                description: 'Savor our selection of gourmet dishes made with fresh ingredients.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Cozy Ambiance',
                                description: 'Enjoy a relaxing atmosphere perfect for any occasion.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'testimonial',
                    content: {
                        title: 'Customer Reviews',
                        background_color: '#fff3e0',
                        text_color: '#333333',
                        testimonials: [
                            {
                                quote: 'A delightful dining experience with delicious food and a welcoming ambiance.',
                                name: 'Sarah M.',
                                image_url: landingPageDefaults.profile,
                            },
                            {
                                quote: 'The best cafe in town! The food is amazing and the staff is friendly.',
                                name: 'David K.',
                                image_url: landingPageDefaults.profile,
                            },
                        ],
                    } as TestimonialSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#ff7043',
                        text_color: '#ffffff',
                        button_color: '#bf360c',
                        text: 'Reserve your table today and enjoy an unforgettable dining experience!',
                        button_text: 'Book Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'food blog':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Delicious Recipes & Culinary Adventures',
                        subheading: 'Join me on a journey of flavors, recipes, and food stories.',
                        background_image: '', // Replace with actual URL
                        background_color: '#f3e5f5',
                        text_color: '#333333',
                        button_color: '#ab47bc',
                        cta_button: {
                            text: 'Read My Blog',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'cards',
                    content: {
                        background_color: '#ffffff',
                        text_color: '#333333',
                        title: 'Recent Posts',
                        cards: [
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'The Perfect Pasta Recipe',
                                description: 'Discover how to make the perfect pasta with this simple recipe.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                            {
                                image_url: landingPageDefaults.profile,
                                title: 'A Guide to Exotic Spices',
                                description: 'Learn about exotic spices and how to use them in your cooking.',
                                link_name: 'Read More',
                                link_url: '#',
                            },
                        ],
                    } as CardsSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#ab47bc',
                        text_color: '#ffffff',
                        button_color: '#8e24aa',
                        text: 'Subscribe to my blog for delicious recipes and culinary tips!',
                        button_text: 'Subscribe Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        case 'online food delivery':
            return [
                {
                    type: 'hero',
                    content: {
                        main_heading: 'Delicious Meals Delivered to Your Doorstep',
                        subheading: 'Order your favorite dishes online and enjoy them at home.',
                        background_image: '', // Replace with actual URL
                        background_color: '#e0f7fa',
                        text_color: '#333333',
                        button_color: '#00796b',
                        cta_button: {
                            text: 'Order Now',
                            url: '#',
                        },
                    } as HeroSection['content'],
                },
                {
                    type: 'features',
                    content: {
                        title: 'Why Choose Us?',
                        background_color: '#ffffff',
                        text_color: '#333333',
                        features: [
                            {
                                title: 'Fast Delivery',
                                description: 'Get your food delivered hot and fresh in no time.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                            {
                                title: 'Wide Variety',
                                description: 'Choose from a wide range of cuisines and dishes.',
                                icon_url: landingPageDefaults.icon, // Replace with actual icon URL
                            },
                        ],
                    } as FeaturesSection['content'],
                },
                {
                    type: 'cta',
                    content: {
                        background_color: '#00796b',
                        text_color: '#ffffff',
                        button_color: '#004d40',
                        text: 'Order now and enjoy a delicious meal delivered to your door!',
                        button_text: 'Order Now',
                        button_url: '#',
                    } as CtaSection['content'],
                },
            ];

        default:
            return []
    }
};

export default getPageContent;


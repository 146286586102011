// src/components/Header.tsx
import React from 'react';
import './Header.css';

const Header: React.FC = () => {
    const isAuthenticated = Boolean(localStorage.getItem('authToken')); // Check if user is authenticated

    return (
        <header className="header">
            <div className="container">
                <div className="logo-container">
                    <img
                        src="/logo512.png"
                        alt="Logo"
                        className="logo"
                    />
                </div>
                {isAuthenticated ? (
                    <>
                        <nav>
                            <a href="/">Home</a>
                            <a href="/?p=product">Product</a>
                            <a href="/?p=pricing">Pricing</a>
                        </nav>

                        <nav className='nav-right '>
                            <a href="/#links">My Links</a>
                            <a href="/#settings">Settings</a>
                            <a href="/#logout">Logout</a>
                        </nav>
                    </>
                ) : (
                    <>
                        <nav className='nav-center '>
                            <a href="/">Home</a>
                            <a href="/?p=product">Product</a>
                            <a href="/?p=pricing">Pricing</a>
                        </nav>

                        <nav className='nav-right '>
                            <a href="/#otp-login">Login</a>
                        </nav>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;
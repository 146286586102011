import React from 'react';

const TermsAndConditions: React.FC = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1>Terms and Conditions</h1>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By using this platform, you agree to comply with these Terms and Conditions. If you do not agree, you must not use the platform.
            </p>

            <h2>2. User Accounts and Usage</h2>
            <p>
                Users must not create multiple accounts with different email addresses to bypass subscription plan limits. If detected, the platform reserves the right to prohibit such activities and may request the user to upgrade their plan accordingly.
                Disposable email addresses are not allowed for account creation. Accounts registered with disposable email addresses may be suspended or terminated without notice.
                The user is solely responsible for the content they create and manage on the platform.
            </p>

            <h2>3. Image and Content Responsibility</h2>
            <p>
                Users are responsible for ensuring that any images uploaded to landing pages are their property or that they have the appropriate rights to use them.
                The platform does not claim ownership of uploaded images but has the right to remove any content that infringes on the rights of others.
            </p>

            <h2>4. Subscription and Cancellation Policy</h2>
            <p>
                Subscription purchases are final. Users can cancel their subscription at any time, but no refunds will be issued for the remaining subscription period. Access to paid features will continue until the subscription end date.
                If a user deletes their account, all data, including short URLs and landing pages, will be deleted. However, if a subscription is active at the time of account deletion, the cancellation policy stated above still applies.
            </p>

            <h2>5. Prohibited Content and Activities</h2>
            <p>
                Users must not create, share, or link to content that promotes racism, harassment, sexual content, criminal activities, or any other content deemed inappropriate.
                Violation of these terms may result in immediate account suspension, along with the disabling of all associated short URLs and landing pages.
            </p>

            <h2>6. Free Plan and Advertisements</h2>
            <p>
                Users on the free plan may encounter advertisements before being redirected to their link. Advertisements will appear at random intervals.
                The platform reserves the right to modify the advertisement policy for free users without prior notice.
            </p>

            <h2>7. Subscription Plans and Benefits</h2>
            <p>
                Subscriptions offer varying benefits as described in the platform's pricing plan. Upgraded plans may include, but are not limited to, increased limits on short URLs, landing pages, and access to custom slugs.
                The platform may change the subscription offerings at any time, with notice provided to affected users.
            </p>

            <h2>8. Account Suspension and Termination</h2>
            <p>
                The platform owner reserves the right to disable or terminate user accounts, including all associated links and landing pages, if these Terms and Conditions are violated.
                Users found circumventing the platform's rules, especially regarding multiple account creation or the use of disposable email addresses, will face suspension or termination without prior notice.
            </p>

            <h2>9. Data Analytics and Usage</h2>
            <p>
                The platform collects and analyzes data related to the usage of short URLs and landing pages for the purpose of providing analytics to the user. By using the platform, users consent to this data collection.
                The platform ensures that this data is used solely for the purpose of enhancing user experience and providing insights and will not be shared with third parties without the user's consent, except as required by law.
            </p>

            <h2>10. Modifications to Terms and Conditions</h2>
            <p>
                The platform reserves the right to modify these Terms and Conditions at any time. Users will be notified of significant changes, and continued use of the platform after changes implies acceptance of the revised terms.
            </p>

            <h2>11. Liability and Disclaimers</h2>
            <p>
                The platform is provided "as is," and the platform owner makes no warranties or guarantees regarding the service's availability, functionality, or performance.
                The platform owner is not liable for any indirect, incidental, or consequential damages resulting from the use or inability to use the platform.
            </p>
        </div>
    );
};

export default TermsAndConditions;
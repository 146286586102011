// src/pages/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1>Privacy Policy</h1>

            <h2>1. Information We Collect</h2>
            <p>
                The platform collects only the user's email address. Authentication is performed through an OTP (One-Time Password) system, ensuring that no passwords are stored on the platform.
                No other personal data is collected from users.
            </p>

            <h2>2. Payment Processing</h2>
            <p>
                The platform uses Stripe as the payment processor. All payment transactions, including credit card and debit card processing, are handled through Stripe's secure checkout process.
                The platform does not collect or store any payment information. The platform only processes confirmation from Stripe regarding the success or failure of a purchase.
            </p>

            <h2>3. Analytics and Access Logs</h2>
            <p>
                The platform uses the user's IP address for analytics and access logs related to short URLs and landing pages. IP addresses are not stored directly. Instead, the platform records the country of origin, as provided by the hosting provider as part of their infrastructure configuration.
                Analytics data is used solely to improve the user experience and the performance of the platform. It is not shared with third parties, except in anonymized or aggregated forms that do not identify individual users.
            </p>

            <h2>4. Data Security and Access</h2>
            <p>
                Access to platform services is restricted to authorized users only. No third-party team has access to user data.
                The platform employs industry-standard security measures to protect user data against unauthorized access, alteration, disclosure, or destruction. This includes encryption, secure servers, and access controls.
                While the platform takes all necessary steps to safeguard user data, it cannot guarantee absolute security. Users acknowledge that they use the platform at their own risk.
            </p>

            <h2>5. User Rights</h2>
            <p>
                Users have the right to request the deletion of their account, which will result in the deletion of all associated data. This process is irreversible, and once completed, all user data, including short URLs, landing pages, and analytics, will be permanently deleted.
                Users can also request to access or update their email information by contacting the platform support.
            </p>

            <h2>6. Email Usage</h2>
            <p>
                The platform shares users' email addresses with a third-party platform for sending transactional emails related to account activity (e.g., passwordless login, purchase confirmations).
                Registered users' email addresses may be used to promote new services or discounts offered by the platform. However, these email addresses will not be shared with third parties for marketing purposes.
                Users can opt-out of promotional emails at any time by following the unsubscribe link included in such communications.
            </p>

            <h2>7. Third-Party Services</h2>
            <p>
                The platform may contain links to third-party websites, services, or applications. This Privacy Policy does not apply to those third-party services, and the platform is not responsible for the privacy practices of those entities.
                Users are encouraged to review the privacy policies of any third-party services they use.
            </p>

            <h2>8. Cookies and Tracking Technologies</h2>
            <p>
                The platform may use cookies and similar tracking technologies to enhance user experience, such as remembering user preferences and providing analytics.
                Users can control the use of cookies at the browser level, but disabling cookies may affect the functionality of the platform.
            </p>

            <h2>9. Changes to this Privacy Policy</h2>
            <p>
                The platform reserves the right to update this Privacy Policy at any time. Users will be notified of significant changes, and continued use of the platform after changes implies acceptance of the revised policy.
            </p>

            <h2>10. Children’s Privacy</h2>
            <p>
                The platform is not intended for use by individuals under the age of 13. The platform does not knowingly collect personal information from children under 13.
                If it is discovered that such information has been inadvertently collected, it will be promptly deleted.
            </p>

            <h2>11. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at support@tzz.at.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
import React from 'react';
import groupedTemplateOptions from './landingPageTemplateGroups';
import getPageContent from './categoryContent';
import { Section } from '../Types';
import { useConfirmationDialog } from '../../ConfirmationDialog/ConfirmationDialogProvider';

interface TemplateDropdownProps {
    onSelected: (sections: Section[]) => void;
}

const TemplateDropdown: React.FC<TemplateDropdownProps> = ({ onSelected }) => {
    const { showConfirmation } = useConfirmationDialog()
    const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const content = getPageContent(event.target.value);
        if (content.length > 0) {
            const ok = await showConfirmation('Applying this template will override your existing sections. Please confirm to proceed further.')
            if (ok) {
                onSelected(content);
            }
        }
    };

    return (
        <div style={{ padding: '5px', marginTop: '10px', marginBottom: '10px', width: '50%' }}>
            <label htmlFor="templateDropdown">Create from existing Template:</label>
            <select
                id="templateDropdown"
                onChange={handleChange}
            >
                <option value="">Select a Template</option>
                {groupedTemplateOptions.map((group) => (
                    <optgroup key={group.label} label={group.label}>
                        {group.options.map((template) => (
                            <option key={template} value={template}>
                                {template}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            <small>Choosing a template will override the current sections.</small>
        </div>
    );
};

export default TemplateDropdown;

import React, { useEffect, useState } from "react";
import { useMessage } from "./MessageContex"; // Import the custom hook

const MessagePopup: React.FC = () => {
    const { popupInfo } = useMessage();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        if (popupInfo.message) {
            setIsVisible(true);

            // Automatically hide the popup after 3 seconds
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [popupInfo]);

    if (!isVisible) {
        return null;
    }

    return (
        <div
            style={{
                position: "fixed",
                top: "20px",
                right: "20px",
                padding: "15px 25px",
                backgroundColor: popupInfo.status === "success" ? "green" : "red",
                color: "white",
                zIndex: 1000,
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                transition: "opacity 0.5s ease-in-out",
            }}
        >
            {popupInfo.message}
        </div>
    );
};

export default MessagePopup;

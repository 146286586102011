// src/services/ApiService.ts
import {
    UpdateUrlRequestDTO,
    CreateUrlRequestDTO,
    TotalClicksResponseDTO,
    ClicksByBrowserResponseDTO,
    ClicksByDeviceResponseDTO,
    TopCountriesResponseDTO,
    ClicksByDateResponseDTO,
    MostTriggeredConditionResponseDTO,
    VerifyOtpResponseDTO,
    GetUrlsResponseDTO,
    GetUrlDataResponseDTO,
    ClicksByHourResponseDTO,
    ClicksByDayResponseDTO,
    SubscriptionDTO,
    ResponseDTO
} from './dtos'; // Import DTOs

interface Grecaptcha {
    execute(siteKey: string, options: { action: string }): Promise<string>;
}

// Extend the Window interface to include grecaptcha
declare global {
    interface Window {
        grecaptcha: Grecaptcha;
    }
}

export default class ApiService {
    private static BASE_URL = window.location.origin + "/api";

    /**
     * Retrieves the authorization token from localStorage.
     * @returns The token or an empty string if not found.
     */
    private static getAuthToken(): string {
        return localStorage.getItem('authToken') || '';
    }

    private static async handleRecaptcha() {
        const token = await window.grecaptcha.execute('6Ld0KT8qAAAAAHzeYNHTNhmHtbxwFEEUYEppB0gF', { action: 'apiRequest' });
        return token;
    }

    public static async getUrls(page: number, limit: number, search: string): Promise<GetUrlsResponseDTO> {
        try {
            let queryString = '?page=' + page + '&limit=' + limit + '&term=' + (search ? search : '');

            const response = await fetch(`${this.BASE_URL}/urls${queryString}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': ''
                },
            });

            const data = await response.json();

            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve URLs');
            }

            // Returning the structured response using the GetUrlsResponseDTO
            return {
                status: 'success',
                urls: data.data, // Assuming the API returns an array of URLs
                totalPages: data.totalPages,
                page: data.page
            };
        } catch (error) {
            return {
                status: 'error',
                urls: [],
                totalPages: 0,
                page: 0,
                message: `Error: ${error}`,
            };
        }
    }

    /**
     * Sends an OTP to the provided email address.
     * @param email - The email address to send the OTP to.
     * @returns A promise resolving to the response message.
     */
    public static async sendOtp(email: string): Promise<ResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Recaptcha': await ApiService.handleRecaptcha()
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to send OTP');
            }

            return data;
        } catch (error) {
            return { status: 'error', message: 'Failed to send OTP' };
        }
    }

    public static async initPaymentSession(priceId: string): Promise<string | null> {
        try {
            const response = await fetch(`${this.BASE_URL}/payment/init-session/${priceId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to initialize payment session');
            }

            if (data.status === 'success' && data.session_id) {
                return data.session_id;
            } else if (data.status === 'success' && data.message) {
                return null;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    public static async getCurrentPlan(): Promise<SubscriptionDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/payment/current-subscription`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to fetch current plan');
            }

            return data;
        } catch (error) {
            return {
                status: 'error', // Assuming status can only be 'success' or 'error'
                settings: {
                    stripeKey: '',
                    plans: []
                },
                plan: '',
                subscription_id: '',
                monthly_links: 0,
                monthly_links_limit: 0,
                landing_pages: 0,
                landing_pages_limit: 0,
                custom_slug: 0,
                custom_slug_limit: 0,
                statistics_weeks: 0,
                statistics_weeks_limit: 0,
                ends_at: '',
                is_canceled: 0,
            };
        }
    }

    /**
     * Verifies the OTP for the provided email address.
     * @param email - The email address associated with the OTP.
     * @param otp - The OTP to verify.
     * @returns A promise resolving to the response message.
     */
    public static async verifyOtp(email: string, otp: number): Promise<VerifyOtpResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Recaptcha': await ApiService.handleRecaptcha()
                },
                body: JSON.stringify({ email, otp }),
            });

            const data = await response.json();

            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to verify OTP');
            }

            // Returning the structured response using the VerifyOtpResponseDTO
            return {
                status: data.status,
                message: data.message,
                token: data.token,
                expires_in: data.expires_in,
            };
        } catch (error) {
            return {
                status: 'error',
                message: `Error: ${error}`,
            };
        }
    }

    /**
     * Retrieves data for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the URL data.
     */
    public static async getUrlData(shortUrl: string): Promise<GetUrlDataResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/url-data/${shortUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve URL data');
            }

            // Ensure the response matches the GetUrlDataResponseDTO structure
            return {
                status: data.status,
                data: {
                    short_url: data.data.short_url,
                    original_url: data.data.original_url,
                    created_at: data.data.created_at,
                    is_landing_page: data.data.is_landing_page === 1,
                    landing_page_content: data.data.landing_page_content,
                    conditions: data.data.conditions,
                },
            };
        } catch (error) {
            return {
                status: 'error',
                data: {
                    short_url: '',
                    original_url: '',
                    created_at: '',
                    is_landing_page: false,
                    landing_page_content: '',
                    conditions: [],
                },
                message: `Error: ${error}`,
            };
        }
    }

    /**
     * Retrieves the total number of clicks for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the total number of clicks.
     */
    public static async getTotalClicks(shortUrl: string): Promise<TotalClicksResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/total-clicks/${shortUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve total clicks');
            }

            return { total_clicks: data.data };
        } catch (error) {
            return { total_clicks: 0 }; // Default response in case of error
        }
    }

    /**
     * Updates an existing shortened URL with new redirection conditions.
     * @param updateData - The update URL request data.
     * @returns A promise resolving to the update status.
     */
    public static async updateUrl(updateData: UpdateUrlRequestDTO): Promise<any> {
        try {
            const response = await fetch(`${this.BASE_URL}/update-url`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData),
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to update URL');
            }

            return data;
        } catch (error) {
            return { status: 'error', message: error };
        }
    }

    /**
     * Creates a new shortened URL with redirection conditions.
     * @param createData - The create URL request data.
     * @returns A promise resolving to the creation status.
     */
    public static async createUrl(createData: CreateUrlRequestDTO): Promise<any> {
        try {
            const response = await fetch(`${this.BASE_URL}/create-url`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(createData),
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to create URL');
            }

            return data;
        } catch (error) {
            return { status: 'error', message: error };
        }
    }

    /**
     * Retrieves clicks by browser for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the clicks by browser data.
     */
    public static async getClicksByBrowser(shortUrl: string, from: string, to: string): Promise<ClicksByBrowserResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/clicks-by-browser/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve clicks by browser');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                data: []
            }; // Default response in case of error
        }
    }

    /**
     * Retrieves clicks by device for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the clicks by device data.
     */
    public static async getClicksByDevice(shortUrl: string, from: string, to: string): Promise<ClicksByDeviceResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/clicks-by-device/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve clicks by device');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                data: []
            };// Default response in case of error
        }
    }

    /**
     * Retrieves top countries for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the top countries data.
     */
    public static async getTopCountries(shortUrl: string, from: string, to: string): Promise<TopCountriesResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/top-countries/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve top countries data');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                data: []
            }; // Default response in case of error
        }
    }

    /**
     * Retrieves clicks by date for a given shortened URL.
     * @param urlId - The unique identifier for the shortened URL.
     * @returns A promise resolving to the clicks by date data.
     */
    public static async getClicksByDate(shortUrl: string, from: string, to: string): Promise<ClicksByDateResponseDTO[]> {
        try {
            const response = await fetch(`${this.BASE_URL}/clicks-by-date/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve clicks by date');
            }
            return data;
        } catch (error) {
            return []; // Default response in case of error
        }
    }

    public static async getMostTriggeredCondition(shortUrl: string, from: string, to: string): Promise<MostTriggeredConditionResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/most-triggered-condition/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',

                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve most triggered condition');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                data: []
            }; // Default response in case of error
        }
    }

    public static async cancelSubscription(subscriptionId: string): Promise<ResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/payment/cancel-subscription/${subscriptionId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to cancel subscripton');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                message: 'Error canceling the subscription. Please contact us via email!'
            };
        }
    }

    public static async deleteUrl(slug: string): Promise<ResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/url/${slug}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to delete url');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                message: 'Error deleting the url!'
            };
        }
    }

    public static async deleteAccount(): Promise<ResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/user/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': '',
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to delete account');
            }

            return data;
        } catch (error) {
            return {
                status: 'error',
                message: 'Error deleting the account. Please contact us via email!'
            };
        }
    }

    /**
     * Fetches the number of clicks grouped by hour for a specific short URL.
     * @param shortUrl - The short URL identifier.
     * @returns A promise resolving to the ClicksByHourResponseDTO.
     */
    public static async getClicksByHour(shortUrl: string, from: string, to: string): Promise<ClicksByHourResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/clicks-by-hour/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': ''
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve hourly click data');
            }

            return {
                status: data.status,
                data: data.data,
            };
        } catch (error) {
            return {
                status: 'error',
                data: [],
                message: `Error: ${error}`,
            };
        }
    }

    /**
     * Fetches the number of clicks grouped by day for a specific short URL.
     * @param shortUrl - The short URL identifier.
     * @returns A promise resolving to the ClicksByDayResponseDTO.
     */
    public static async getClicksByDay(shortUrl: string, from: string, to: string): Promise<ClicksByDayResponseDTO> {
        try {
            const response = await fetch(`${this.BASE_URL}/clicks-by-day/${shortUrl}?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.getAuthToken()}`,
                    'X-Recaptcha': ''
                },
            });

            const data = await response.json();
            if (response.status === 401) {
                ApiService.logOut();
            }
            if (response.status === 400) {
                return data;
            }
            if (!response.ok) {
                throw new Error(data.message || 'Failed to retrieve daily click data');
            }

            return {
                status: data.status,
                data: data.data,
            };
        } catch (error) {
            return {
                status: 'error',
                data: [],
                message: `Error: ${error}`,
            };
        }
    }

    public static logOut() {
        localStorage.removeItem("authToken");
        window.location.href = "/#otp-login";
        window.location.reload();
    }
}
import React, { ChangeEvent } from 'react';
import { HeroSection } from '../../Types';
import ImageInputField from '../InputComponents/ImageInputField';

interface HeroSectionFormProps {
  initialContent: HeroSection['content'];
  onSave: (updatedContent: HeroSection['content']) => void;
}

const HeroSectionForm: React.FC<HeroSectionFormProps> = ({ initialContent, onSave }) => {
  const [content, setContent] = React.useState<HeroSection['content']>(initialContent);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleSave = () => {
    onSave(content);
  };

  return (
    <div>
      <label>Colors:</label>
      <div className="grid-div">
        <small>
          Background:
        </small>
        <input
          type="color"
          name="background_color"
          value={content.background_color}
          onChange={handleChange}
        />
        <small>
          Text:
        </small>
        <input
          type="color"
          name="text_color"
          value={content.text_color}
          onChange={handleChange}
        />
        <small>
          Button:
        </small>
        <input
          type="color"
          name="button_color"
          value={content.button_color}
          onChange={handleChange}
        />
        <small>
          Button Text:
        </small>
        <input
          type="color"
          name="button_text_color"
          value={content.button_text_color}
          onChange={handleChange}
        />
      </div>
      <label>
        Main Heading:
      </label>
      <input
        type="text"
        name="main_heading"
        value={content.main_heading}
        onChange={handleChange}
      />
      <label>
        Subheading:
      </label>
      <input
        type="text"
        name="subheading"
        value={content.subheading}
        onChange={handleChange}
      />
      <label>
        Background Image URL:
      </label>
      <ImageInputField
        name="background_image"
        value={content.background_image}
        onChange={handleChange} />
      <label>
        Button Text:
      </label>
      <input
        type="text"
        name="cta_button_text"
        value={content.cta_button.text}
        onChange={(e) => setContent({ ...content, cta_button: { ...content.cta_button, text: e.target.value } })}
      />
      <label>
        Button URL:
      </label>
      <input
        type="text"
        name="cta_button_url"
        value={content.cta_button.url}
        onChange={(e) => setContent({ ...content, cta_button: { ...content.cta_button, url: e.target.value } })}
      />
      <br></br>

      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default HeroSectionForm;
// src/pages/LandingPage.tsx
import React, { useEffect, useRef, useState } from 'react';
import './LandingPage.css'; // Assuming we have some styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faChartBar, faCogs, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import PricingSection from '../../components/PricingSection/PricingSection';
import PricingComparison from '../../components/PricingComparison/PricingComparison';

const LandingPage: React.FC = () => {
  const product = useRef<HTMLElement | null>(null);
  const pricing = useRef<HTMLElement | null>(null);

  // Dummy data for demonstration purposes
  const totalShortUrls = 1500;  // Example: Total number of short URLs created
  const totalRedirections = 7500;  // Example: Total number of redirections
  const totalMicroPages = 125;  // Example: Total number of redirections

  // States to handle animated numbers
  const [shortUrlsCount, setShortUrlsCount] = useState(0);
  const [redirectionsCount, setRedirectionsCount] = useState(0);
  const [microPagesCount, setMicroPagesCount] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subPage = urlParams.get('p');
    if (subPage === "product") {
      product.current?.scrollIntoView()
    } else if (subPage === "pricing") {
      pricing.current?.scrollIntoView()
    }
  }, [product, pricing]);

  // Effect to handle number animation
  useEffect(() => {
    let shortUrlsStart = 0;
    let redirectionsStart = 0;
    let microPagesStart = 0;

    const shortUrlsInterval = setInterval(() => {
      if (shortUrlsStart < totalShortUrls) {
        shortUrlsStart += Math.ceil(totalShortUrls / 100); // Increment logic
        setShortUrlsCount(shortUrlsStart);
      } else {
        setShortUrlsCount(totalShortUrls);
        clearInterval(shortUrlsInterval);
      }
    }, 30);

    const redirectionsInterval = setInterval(() => {
      if (redirectionsStart < totalRedirections) {
        redirectionsStart += Math.ceil(totalRedirections / 100); // Increment logic
        setRedirectionsCount(redirectionsStart);
      } else {
        setRedirectionsCount(totalRedirections);
        clearInterval(redirectionsInterval);
      }
    }, 30);

    const micropagesInterval = setInterval(() => {
      if (microPagesStart < totalMicroPages) {
        microPagesStart += Math.ceil(totalMicroPages / 100); // Increment logic
        setMicroPagesCount(microPagesStart);
      } else {
        setRedirectionsCount(totalMicroPages);
        clearInterval(micropagesInterval);
      }
    }, 30);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(shortUrlsInterval);
      clearInterval(redirectionsInterval);
      clearInterval(micropagesInterval);
    };
  }, [totalShortUrls, totalRedirections, totalMicroPages]);

  return (
    <div className="landing-page">
      <header className="landing-header">
        <div className="text-content">
          <h1>Less is More: Shorten Your URLs and Create Stunning Landing Pages Effortlessly</h1>
          <p>Get started with our powerful URL shortener and landing page builder to unlock the potential of simplified sharing, engaging user experiences, and comprehensive link analytics.</p>
          <button className="get-started-button">Get Started</button>
        </div>
        <div className="image-content">
          <img src="/links.png" alt="URL Shortening Illustration" />
        </div>
      </header>

      <section className="stats-section">
        <h2>The Pulse of Our Platform</h2>
        <h3>Live Stats that Highlight the Reach and Impact of Your Shortened URLs.</h3>
        <div className="stats">
          <div className="stat">
            <h3>{shortUrlsCount.toLocaleString()}</h3>
            <p>Short URLs Created</p>
          </div>
          <div className="stat">
            <h3>{redirectionsCount.toLocaleString()}</h3>
            <p>Total Redirections</p>
          </div>
          <div className="stat">
            <h3>{microPagesCount.toLocaleString()}</h3>
            <p>Total Landing pages</p>
          </div>
        </div>
      </section>

      <section ref={product} className="feature-section">
        <h2>Transform Links into Strategic Assets</h2>
        <h3>Unlock control and insights with advanced features. Make every link a part of your strategy.</h3>
        <div className="features">
          <div className="feature">
            <FontAwesomeIcon icon={faLink} className="feature-icon" />
            <h3>Simple URL Shortening</h3>
            <ul>
              <li>Enter your long URL and get a shorter, shareable link.</li>
              <li>Customize your slugs.</li>
              <li>Generate a unique, random slug effortlessly.</li>
            </ul>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faChartBar} className="feature-icon" />
            <h3>Advanced Analytics</h3>
            <ul>
              <li>Track clicks and monitor traffic sources.</li>
              <li>View detailed reports by period, country, device, and browser.</li>
            </ul>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCogs} className="feature-icon" />
            <h3>Custom Redirection</h3>
            <ul>
              <li>Set up redirection rules based on country, OS, browser, or device.</li>
              <li>Use different redirections for the same short URL.</li>
            </ul>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faFileAlt} className="feature-icon" />
            <h3>Landing pages</h3>
            <ul>
              <li>Create simple pages with predefined layouts.</li>
              <li>Engage with your audience before redirecting.</li>
            </ul>
          </div>
        </div>
      </section>

      <section ref={pricing}></section>
      <PricingSection />

      <PricingComparison />

      <section className="cta-section">
        <h2>Get Started</h2>
        <p>Get started today by creating your account! Start shortening your URLs and build personalized landing pages to boost your brand and engage your audience effectively.</p>
        <a href="/#otp-login" className="cta-button">Start now</a>
      </section>
    </div>
  );
};

export default LandingPage;


export const socialMediaSvgs: Record<string, (color: string) => string> = {
  facebook: (color: string): string => { return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="-143 145 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m-143 145v512h512v-512h-512zm312.5 212.6-2.9 38.3h-39.3v133h-49.6v-133h-26.5v-38.3h26.5v-25.7c0-11.3 0.3-28.8 8.5-39.7 8.7-11.5 20.6-19.3 41.1-19.3 33.4 0 47.4 4.8 47.4 4.8l-6.6 39.2s-11-3.2-21.3-3.2-19.5 3.7-19.5 14v29.9h42.2z"/></svg>`; },
  twitter: (color: string): string => {
    return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="-143 145 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="m-143 145v512h512v-512h-512zm358.2 216.2c0.1 2.2 0.1 4.5 0.1 6.8 0 69.5-52.9 149.7-149.7 149.7-29.7 0-57.4-8.7-80.6-23.6 4.1 0.5 8.3 0.7 12.6 0.7 24.6 0 47.3-8.4 65.3-22.5-23-0.4-42.5-15.6-49.1-36.5 3.2 0.6 6.5 0.9 9.9 0.9 4.8 0 9.5-0.6 13.9-1.9-24.1-4.8-42.2-26.1-42.2-51.6v-0.6c7.1 3.9 15.2 6.3 23.8 6.6-14.1-9.4-23.4-25.6-23.4-43.8 0-9.6 2.6-18.7 7.1-26.5 26 31.9 64.7 52.8 108.4 55-0.9-3.8-1.4-7.8-1.4-12 0-29 23.6-52.6 52.6-52.6 15.1 0 28.8 6.4 38.4 16.6 12-2.4 23.2-6.7 33.4-12.8-3.9 12.3-12.3 22.6-23.1 29.1 10.6-1.3 20.8-4.1 30.2-8.3-7 10.6-15.9 19.8-26.2 27.3z"/>
</svg>
`;
  },
  instagram: (color: string): string => {
    return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="-143 145 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">

	<path d="m183 401c0 38.6-31.4 70-70 70s-70-31.4-70-70c0-9.3 1.9-18.2 5.2-26.3h-38.2v104.8c0 13.5 11 24.5 24.5 24.5h157c13.5 0 24.5-11 24.5-24.5v-104.8h-38.2c3.4 8.1 5.2 17 5.2 26.3z"/>
	<polygon points="211.4 345.9 211.4 308.1 211.4 302.5 205.8 302.5 168 302.6 168.2 346"/>
	<path d="m113 446c24.8 0 45.1-20.2 45.1-45.1 0-9.8-3.2-18.9-8.5-26.3-8.2-11.3-21.5-18.8-36.5-18.8s-28.3 7.4-36.5 18.8c-5.3 7.4-8.5 16.5-8.5 26.3-0.1 24.9 20.1 45.1 44.9 45.1z"/>
	<path d="m-143 145v512h512v-512h-512zm384 229.7v104.8c0 27.3-22.2 49.5-49.5 49.5h-157c-27.3 0-49.5-22.2-49.5-49.5v-157.1c0-27.3 22.2-49.5 49.5-49.5h157c27.3 0 49.5 22.2 49.5 49.5v52.3z"/>

</svg>
`;
  },
  tiktok: (color: string): string => {
    return `<svg fill="${color}" width="50px" height="50px" version="1.1" viewBox="0 0 256 256" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.4066 1.4066) scale(2.81)">
        <path d="m0 0v90h90v-90h-90zm71.039 33.277v6.758c-3.187 1e-3 -6.283-0.623-9.203-1.855-1.878-0.793-3.627-1.814-5.227-3.048l0.048 20.801c-0.02 4.684-1.873 9.085-5.227 12.4-2.73 2.698-6.188 4.414-9.937 4.97-0.881 0.13-1.777 0.197-2.684 0.197-4.013 0-7.823-1.3-10.939-3.698-0.586-0.452-1.147-0.941-1.681-1.468-3.635-3.593-5.509-8.462-5.194-13.584 0.241-3.899 1.802-7.618 4.404-10.532 3.443-3.857 8.26-5.998 13.41-5.998 0.906 0 1.803 0.068 2.684 0.198v9.45c-0.835-0.275-1.727-0.427-2.656-0.427-4.705 0-8.512 3.839-8.442 8.548 0.045 3.013 1.69 5.646 4.118 7.098 1.141 0.682 2.453 1.105 3.853 1.182 1.097 0.06 2.151-0.093 3.126-0.415 3.362-1.111 5.787-4.268 5.787-7.992l0.011-13.93v-25.432h9.307c9e-3 0.922 0.103 1.822 0.276 2.694 0.702 3.529 2.692 6.591 5.46 8.678 2.414 1.821 5.42 2.9 8.678 2.9 2e-3 0 0.029 0 0.027-2e-3v2.507z" stroke-linecap="round"/>
      </g>
      </svg>`;
  },
  linkedin: (color: string): string => {
    return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="-143 145 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="m-143 145v512h512v-512h-512zm184.4 363.1h-49.9v-159.7h49.9v159.7zm-26.3-179.7h-0.4c-18.1 0-29.8-12.2-29.8-27.7 0-15.8 12.1-27.7 30.5-27.7s29.7 11.9 30.1 27.7c0.1 15.4-11.6 27.7-30.4 27.7zm225.9 179.7h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4-14.9 0-23.2 10-27 19.6-1.4 3.4-1.2 8.2-1.2 13.1v86.3h-56.1s0.7-146.4 0-159.7h56.1v25.1c3.3-11 21.2-26.6 49.8-26.6 35.5 0 63.3 23 63.3 72.4v88.8z"/>
</svg>`;
  },
  youtube: (color: string): string => {
    return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="-143 145 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><polygon points="78.9 450.3 162.7 401.1 78.9 351.9"/>
	<path d="m-143 145v512h512v-512h-512zm384 301.8c0 44.1-44.1 44.1-44.1 44.1h-167.8c-44.1 0-44.1-44.1-44.1-44.1v-91.5c0-44.1 44.1-44.1 44.1-44.1h167.8c44.1 0 44.1 44.1 44.1 44.1v91.5z"/>`
  },
  spotify: (color: string): string => {
    return `<svg width="50px" height="50px" fill="${color}" version="1.1" viewBox="0 0 455 455" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g clip-rule="evenodd" fill-rule="evenodd">
	<path d="m291.66 281.99c-26.425-15.854-57.133-23.767-91.591-23.767-20.121 0-39.494 2.534-58.958 6.973-4.755 0.908-8.554 4.207-8.554 10.819 0 5.159 3.97 9.898 9.958 9.898 1.941 0 5.175-0.985 7.757-1.549 15.87-3.298 32.833-5.456 49.688-5.456 30.192 0 58.662 7.257 81.489 21.062 2.378 1.453 4.111 2.33 6.66 2.33 5.175 0 9.79-4.159 9.79-9.851 1e-3 -5.832-2.301-8.177-6.239-10.459z"/>
	<path d="m310.36 235.85c-31.348-18.76-71.234-29.035-112.46-29.035-26.313 0-44.389 3.755-62.1 8.618-6.536 1.845-9.79 6.456-9.79 13.149 0 6.832 5.536 12.352 12.304 12.352 2.862 0 4.583-0.909 7.661-1.769 14.245-3.783 31.46-6.58 51.409-6.58 38.869 0 74.376 10.099 100 25.296 2.33 1.361 4.471 2.738 7.893 2.738 6.849 0 12.276-5.503 12.276-12.32-1e-3 -5.6-1.942-9.431-7.193-12.449z"/>
	<path d="m0 0v455h455v-455h-455zm227.49 384.99c-86.98 0-157.49-70.514-157.49-157.49 0-86.992 70.514-157.49 157.49-157.49 86.976 0 157.51 70.498 157.51 157.49 0 86.98-70.53 157.49-157.51 157.49z"/>
	<path d="m331.56 183.08c-35.539-20.873-84.211-30.38-130.44-30.38-27.254 0-52.302 3.078-74.769 9.694-5.708 1.689-11.007 6.488-11.007 15.038 0 8.413 6.444 15.058 14.838 15.058 2.91 0 5.784-1.157 8.085-1.721 19.873-5.391 41.559-7.565 62.913-7.565 42.78 0 87.012 9.41 115.53 26.501 3.002 1.641 4.907 2.422 8.097 2.422 7.957 0 14.854-6.16 14.87-14.742 0-7.504-3.331-11.587-8.114-14.305z"/>
</g>
</svg>`
  }
};

export default socialMediaSvgs;
// src/pages/Home.tsx
import React from 'react';
import UrlList from '../../components/UrlList/UrlList';
import './Links.css';

const Links: React.FC = () => {
    return (
        <div className={"full-width"}>
            <UrlList />
        </div>
    );
};

export default Links;
// src/pages/StatisticsPage.tsx
import React, { useState, useEffect } from 'react';
import { Bar, Line, Doughnut, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement, RadialLinearScale } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ApiService from '../../services/ApiService';
import { DayClickDataDTO, HourClickDataDTO, MostTriggeredConditionDTO, TopCountriesDTO, ClicksByBrowserDTO, ClicksByDeviceDTO } from '../../services/dtos';
import './StatisticsPage.css';
import { useParams } from 'react-router-dom';

// Register chart.js components
ChartJS.register(
    CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement, RadialLinearScale
);

const StatisticsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const [clicksByDayData, setClicksByDayData] = useState<DayClickDataDTO[]>([]);
    const [clicksByHourData, setClicksByHourData] = useState<HourClickDataDTO[]>([]);
    const [clicksByBrowserData, setClicksByBrowserData] = useState<ClicksByBrowserDTO[]>([]);
    const [clicksByDeviceData, setClicksByDeviceData] = useState<ClicksByDeviceDTO[]>([]);
    const [topCountriesData, setTopCountriesData] = useState<TopCountriesDTO[]>([]);
    const [mostTriggeredConditionData, setMostTriggeredConditionData] = useState<MostTriggeredConditionDTO[] | null>(null);
    const [totalClicks, setTotalClicks] = useState<number>(0);

    const [currentWeekStart, setCurrentWeekStart] = useState<Date | null>(null);
    const [currentWeekEnd, setCurrentWeekEnd] = useState<Date | null>(null);

    const [loading, setLoading] = useState<boolean>(true); // Component-level loading state

    const fetchStatistics = async (from: string, to: string) => {
        setLoading(true); // Set loading to true before fetching
        try {
            const clicksByDayResponse = await ApiService.getClicksByDay(id + "", from, to);
            if (clicksByDayResponse.status === 'success') {
                setClicksByDayData(clicksByDayResponse.data);
            }

            const clicksByHourResponse = await ApiService.getClicksByHour(id + "", from, to);
            if (clicksByHourResponse.status === 'success') {
                setClicksByHourData(clicksByHourResponse.data);
            }

            const clicksByBrowserResponse = await ApiService.getClicksByBrowser(id + "", from, to);
            if (clicksByBrowserResponse.status === 'success') {
                setClicksByBrowserData(clicksByBrowserResponse.data);
            }

            const clicksByDeviceResponse = await ApiService.getClicksByDevice(id + "", from, to);
            if (clicksByDeviceResponse.status === 'success') {
                setClicksByDeviceData(clicksByDeviceResponse.data);
            }

            const topCountriesResponse = await ApiService.getTopCountries(id + "", from, to);
            if (topCountriesResponse.status === 'success') {
                setTopCountriesData(topCountriesResponse.data);
            }

            const totalClicksData = await ApiService.getTotalClicks(id + "");
            setTotalClicks(totalClicksData.total_clicks);

            setLoading(false); // Set loading to false after fetching
        } catch (error) {
            console.error('Failed to fetch statistics data.');
            setLoading(false); // Set loading to false even if there's an error
        }
    };

    const formatDate = (date: Date | null) => {
        if (date == null) {
            return '';
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        fetchStatistics(formatDate(currentWeekEnd), formatDate(currentWeekStart));
    }, [currentWeekStart, currentWeekEnd]);

    useEffect(() => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        setCurrentWeekStart(today);
        setCurrentWeekEnd(oneWeekAgo);
    }, [id]);

    // Prepare chart data
    const clicksByDayChartData = {
        labels: clicksByDayData.map((data) => data.day),
        datasets: [
            {
                label: 'Clicks by Day',
                data: clicksByDayData.map((data) => data.clicks),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const clicksByHourChartData = {
        labels: clicksByHourData.map((data) => data.hour),
        datasets: [
            {
                label: 'Clicks by Hour',
                data: clicksByHourData.map((data) => data.clicks),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };

    const clicksByBrowserChartData = {
        labels: clicksByBrowserData.map((data) => data.browser),
        datasets: [
            {
                label: 'Clicks by Browser',
                data: clicksByBrowserData.map((data) => data.clicks),
                backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(75, 192, 192, 0.6)'],
            },
        ],
    };

    const topCountriesChartData = {
        labels: topCountriesData.map((data) => data.geo_country),
        datasets: [
            {
                label: 'Clicks by Country',
                data: topCountriesData.map((data) => data.clicks),
                backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
            },
        ],
    };

    const clicksByDeviceRadarData = {
        labels: clicksByDeviceData.map((data) => data.device_type),
        datasets: [
            {
                label: 'Clicks by Device',
                data: clicksByDeviceData.map((data) => data.clicks),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 2,
            },
        ],
    };

    return (
        <div className="statistics-page">
            <h2>Statistics</h2>

            {loading ? (
                <div className="loading-container">
                    <p>Loading data...</p>
                </div>
            ) : (
                <>
                    <div className="total-clicks-box">
                        <h3>Total Clicks</h3>
                        <p>{totalClicks}</p>
                    </div>

                    <div className="date-picker-container">
                        <div className="date-picker-row">
                            <div>
                                <label>From:</label>
                                <DatePicker selected={currentWeekEnd} onChange={date => setCurrentWeekEnd(date)} />
                            </div>
                            <div>
                                <label>To:</label>
                                <DatePicker selected={currentWeekStart} onChange={date => setCurrentWeekStart(date)} />
                            </div>
                        </div>
                    </div>

                    <div className="chart-grid">
                        <div className="chart-container">
                            <h3>Clicks by Day</h3>
                            <Bar data={clicksByDayChartData} />
                        </div>

                        <div className="chart-container">
                            <h3>Clicks by Hour</h3>
                            <Line data={clicksByHourChartData} />
                        </div>

                        <div className="chart-container">
                            <h3>Clicks by Browser</h3>
                            <Doughnut data={clicksByBrowserChartData} />
                        </div>

                        <div className="chart-container">
                            <h3>Clicks by Device</h3>
                            <Radar data={clicksByDeviceRadarData} />
                        </div>

                        <div className="chart-container">
                            <h3>Top Countries by Clicks</h3>
                            <Doughnut data={topCountriesChartData} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StatisticsPage;

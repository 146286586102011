// src/components/Footer.tsx
import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 tzz.at. All rights reserved.</p>
            <div className="footer-links">
                <a href="/tzz-academy">TZZ Academy</a>
                <a href="/#privacy-policy">Privacy Policy</a>
                <a href="/#terms-and-conditions">Terms and Conditions</a>
                <a href="mailto:support@tzz.at">Contact Support</a>
            </div>
        </footer>
    );
};

export default Footer;
